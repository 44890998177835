input{
  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: rgb(158, 157, 157) !important; 
  }
  &::-moz-placeholder { /* Firefox 19+ */
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: rgb(158, 157, 157) !important;
  }
  &:-ms-input-placeholder { /* IE 10+ */
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: rgb(158, 157, 157) !important;
  }
  &:-moz-placeholder { /* Firefox 18- */
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: rgb(158, 157, 157) !important;
  }
}
