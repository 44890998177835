@tailwind base;
@tailwind components;
@tailwind utilities;

.card {
  @apply p-5 border border-gray-200 rounded-xl bg-white shadow-lg ;
}
.form-card {
  @apply p-10 border border-gray-200 rounded-xl bg-white shadow-lg ;
} 
.btn-active {
  @apply bg-new-green px-5 py-2 rounded-lg font-bold text-base text-white;
}
.btn-inactive {
  @apply rounded-lg px-5 py-2 border border-gray-300 font-bold text-base text-gray-400;
}
.form-header {
  @apply p-5 w-1/5 uppercase text-3xl font-bold;
}


input:invalid.submitted{
  border:1px solid red;
}

select:invalid.submitted{
  border:1px solid red;
}

select {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAUCAMAAACtdX32AAAAdVBMVEUAAAD///8AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAhMdQaAAAAJ3RSTlMAAAECAwQGBwsOFBwkJTg5RUZ4eYCHkJefpaytrsXGy8zW3+Do8vNn0bsyAAAAYElEQVR42tXROwJDQAAA0Ymw1p9kiT+L5P5HVEi3qJn2lcPjtIuzUIJ/rhIGy762N3XaThqMN1ZPALsZPEzG1x8LrFL77DHBnEMxBewz0fJ6LyFHTPL7xhwzWYrJ9z22AqmQBV757MHfAAAAAElFTkSuQmCC);
  background-position: 100%;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 1600px) { 
  body{
    zoom: 80% !important;
  }
  .empty{
    height: 125vh;
  }
}

