.green{
  position: relative;
  top: -10px;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  span {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border: 2px solid #919191;
    border-radius: 4px;
  }
  input[type='checkbox']:checked ~ span {
    border: 2px solid #5A953E;
  }
  span::after {
    content: "";
    position: absolute;
    display: none;
  }
  input:checked ~ span::after {
    display: block;
  }
  span::after {
    left: 6px;
    top: 1px;
    width: 6px;
    height: 12px;
    border: solid green;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
