.background {
  background: url('../assets/background.png') no-repeat; 
  background-size: cover;
  width: 100vw;
  height: 100vh;
}
@media only screen and (max-width: 1600px) { 
  .background{
    width: calc(125vw) ;
    height: calc(125vh) ;
  }
}

