.react-transform-component {
  width: unset !important;
  height: unset !important;
}

.react-transform-wrapper {
  width: unset !important;
  height: unset !important;
}

img {
  max-width: 100%;
  max-height: 100%;
  display: block;
}

.react-transform-component {
  width: 100% !important;
  height: 100% !important;
}

.react-transform-wrapper {
  width: 100% !important;
  height: 100% !important;
}