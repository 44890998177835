.authContainer {
    min-height: calc(100vh - 80px);
    max-height: calc(100vh - 80px);
    overflow-y: scroll;
}
@media only screen and (max-width: 1600px) {  
    .authContainer{
      min-height: calc(125vh - 80px) !important;
      max-height: calc(125vh - 80px) !important;
      overflow-y: scroll !important; 
    }
  }
  