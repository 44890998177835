.green{
  input[type='radio']:checked::after {
    width: 13px;
    height: 13px;
    border-radius: 7px;
    top: -3px;
    position: relative;
    background-color: #5A953E;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid #ffffff;
    box-shadow:0 0 0 1px #5A953E;
  }
}
