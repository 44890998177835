@import '~antd/dist/antd.less';
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");

body {
  font-family: "Nunito Sans" !important;
  color: "#454545" !important;
}
.bg-inactive {
  background: rgba(206, 206, 206, 1);
}
.border-thin {
  border: 1px solid rgba(144, 144, 144, 1);
}

.input-normal {
  height: 50px;
  border-radius: 8px;
  border: 1px solid rgba(206, 206, 206, 1);
}
.btn-normal {
  border-radius: 8px;
  padding: 10px;
  border: 1px solid rgba(69, 69, 69, 1);
}
.bg-oro-gradient {
  background: linear-gradient(180deg, #f9ca36 0%, #f8b830 100%);
}
.btn-cta {
  background: linear-gradient(180deg, #f9ca36 0%, #f8b830 100%);
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 9999px;
}
.btn-medium-yellow {
  background-color: #ffe1a8;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
}
.tag-font {
  font-size: 12px;
  font-style: normal;
  font-weight: 800;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}
.small-label {
  font-size: 10px;
  font-style: normal;
  font-weight: 800;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: uppercase;
  color: rgb(158, 157, 157);
}
.text-header-1 {
  font-style: normal;
  font-weight: 800;
  font-size: 64px;
  line-height: 87px;
}
.text-header-2 {
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 65px;
}

.input-border {
  border: "1px solid rgba(206, 206, 206, 1)";
}
.card-border {
  border: 1px solid rgba(206, 206, 206, 1);
}

.btn-medium-yellow {
  background-color: #ffe1a8;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
}

.text-header-3 {
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
}
.text-body-large {
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
}
.text-body-normal {
  font-size: 16px;
  line-height: 22px;
}
.label {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  color: rgba(144, 144, 144, 1);
  text-transform: uppercase;
}
.text-body-bold {
  font-weight: bold !important;
  font-size: 16px;
  line-height: 16px;
}
.text-body-italic {
  font-style: italic;
  font-size: 16px;
  line-height: 22px;
}
.text-small {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}
.text-small-bold {
  font-weight: 800;
  font-size: 12px;
  line-height: 16px;
}
.text-eyebrow {
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  /* identical to box height */

  text-transform: uppercase;
}
.text-button {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}
.text-link {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-decoration-line: underline;
}
.text-bold-button {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  text-transform: capitalize;
}
.text-small-label {
  font-weight: 800;
  font-size: 8px;
  line-height: 11px;
  /* identical to box height */

  text-transform: uppercase;
}
.text-medium-body {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
}
.text-cta {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
}
.text-subtle {
  color: rgb(158, 157, 157);
}
.text-inactive {
  color: rgba(206, 206, 206, 1);
}



.ant-menu-item-selected {
  color: black;
}

/* li.ant-menu-item.ant-menu-item-active{
  color: rgba(17, 24, 39); 
}
.ant-menu-title-content:hover{
  color:black !important;
}
.ant-menu-item:hover  .ant-menu-title-content{
    color: rgba(17, 24, 39);
} 
.ant-menu-light .ant-menu-submenu-title:hover   {
  color: rgba(17, 24, 39);
} */
li.ant-menu-item.ant-menu-item-selected::after {
  transform: scaleY(0);
  opacity: 0;
}
.ant-menu-item-selected {
  border-left: 5px solid #ffc351;
}

.ant-menu-submenu-title {
  color: rgba(17, 24, 39) !important;
}

.ant-menu-submenu:hover
  > .ant-menu-submenu-title
  > .ant-menu-submenu-expand-icon,
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  color: rgba(17, 24, 39);
}



.ant-table-thead {
  -webkit-box-shadow: 0px 15px 50px 0px rgba(0, 0, 0, 0.08) !important;
}

th.ant-table-cell {
  background-color: #ffe1a8 !important;
}
thead > tr > th {
  color: black !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 22px !important;
  letter-spacing: 0em !important;
}

table {
  border-collapse: separate !important;
  border-spacing: 0 0.5em !important;
  border: none !important;
}

.ant-table-content {
  background-color: rgb(243, 244, 246) !important;
}
.ant-table-row {
  background-color: white !important;
}
td.ant-table-column-sort {
  background-color: white !important;
}

.ant-table-tbody > tr > td {
  border-bottom: none !important;
}

.ant-pagination-prev {
  background-color: transparent;
}
.ant-pagination-prev > .ant-pagination-item-link {
  border: none !important;
  background-color: transparent;
}

.ant-pagination-item {
  border: 1px solid gray;
  background-color: transparent;
  border-radius: 100%;
}
.ant-pagination-item-active {
  background-color: black;
}
.ant-pagination-item-active > a {
  color: white;
}
.ant-pagination-item-active:hover > a {
  color: white;
}
.ant-pagination-next > .ant-pagination-item-link {
  border: none !important;
  background-color: transparent;
}


.ant-picker-content > thead > tr > th {
  background-color: #ffe1a8 !important;
  /* color: white !important; */
}
.ant-modal-content {
  border-radius: 20px;
}

.ant-picker > .ant-picker-input > input {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
}

.ant-picker-time-panel-cell.ant-picker-time-panel-cell-selected
  > .ant-picker-time-panel-cell-inner {
  background-color: #ffe1a8 !important;
}

.ant-menu-item-selected {
  border-left: none;
}
.ant-menu-title-content {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
}
.ant-menu-title-content {
  border-left: 5px solid transparent;
  padding-left: 10px;
}

.ant-menu-item-selected > .ant-menu-title-content {
  border-left: 5px solid #ffc351;
  padding-left: 10px;
}
.ant-menu-submenu-title > .ant-menu-title-content {
  border-left: none;
  padding: 0px;
}
.ant-menu-submenu-title {
  margin-left: 15px !important;
}

.ant-select-selection-search > input.ant-select-selection-search-input {
  margin-top: 8px !important;
}
.ant-select > .ant-select-selector {
  height: 50px !important;
}

.ant-select > .ant-select-selector > .ant-select-selection-item {
  margin-top: 5px !important;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
}

span.ant-select-selection-placeholder {
  margin-top: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: rgb(158, 157, 157) !important;
}
.ant-select-multiple
  > .ant-select-selector
  span.ant-select-selection-placeholder {
  margin-top: 0px !important;
}
.ant-select-selector
  > .ant-select-selection-overflow
  > .ant-select-selection-overflow-item {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
}
.ant-menu-submenu-arrow {
  margin-right: 30px;
}
.ant-layout-sider{
  min-height: calc(100vh - 80px) !important;
}
.content{
  min-height: calc(100vh - 80px) !important;
  overflow-y:scroll ;
}
.ant-pagination-options {
  display: none;
}
@primary-color: black;@menu-item-active-bg: transparent;@table-header-bg: #FFE1A8;@table-header-color: black;@calendar-bg: white;@picker-basic-cell-active-with-range-color: white;@picker-basic-cell-hover-color: whitesmoke;@picker-basic-cell-hover-with-range-color: whitesmoke;@dropdown-selected-color: whitesmoke;@select-item-selected-bg: white;@radio-dot-color: rgba(90, 149, 62, 1);@menu-inline-submenu-bg: white;@checkbox-color: rgba(90, 149, 62, 1);@checkbox-size: 25px;